export default [
  {
    key: 'media_id',
    image: 'image_url',
    label: 'Foto Brand',
    type: 'image',
    required: true,
    notes: [
      'Wajib menggunakan gambar dengan <b>rasio kotak</b>.',
      'e.g. 480 x 480 px, 256 x 256 px, dll.',
    ],
  },
  {
    key: 'name',
    label: 'Nama',
    required: true,
    notes: [
      'Nama brand yang akan ditampilkan.',
    ],
  },
]
